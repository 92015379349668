let searchableObjects = [
    {label: "aeroplane", value: "aeroplane"},
    {label: "apple", value: "apple"},
    {label: "backpack", value: "backpack"},
    {label: "banana", value: "banana"},
    {label: "baseball bat", value: "baseball bat"},
    {label: "baseball glove", value: "baseball glove"},
    {label: "bear", value: "bear"},
    {label: "bed", value: "bed"},
    {label: "bench", value: "bench"},
    {label: "bicycle", value: "bicycle"},
    {label: "bird", value: "bird"},
    {label: "boat", value: "boat"},
    {label: "book", value: "book"},
    {label: "bottle", value: "bottle"},
    {label: "bowl", value: "bowl"},
    {label: "broccoli", value: "broccoli"},
    {label: "bus", value: "bus"},
    {label: "cake", value: "cake"},
    {label: "car", value: "car"},
    {label: "carrot", value: "carrot"},
    {label: "cat", value: "cat"},
    {label: "cell phone", value: "cell phone"},
    {label: "chair", value: "chair"},
    {label: "clock", value: "clock"},
    {label: "cow", value: "cow"},
    {label: "cup", value: "cup"},
    {label: "diningtable", value: "diningtable"},
    {label: "dog", value: "dog"},
    {label: "donut", value: "donut"},
    {label: "elephant", value: "elephant"},
    {label: "fire hydrant", value: "fire hydrant"},
    {label: "fork", value: "fork"},
    {label: "frisbee", value: "frisbee"},
    {label: "giraffe", value: "giraffe"},
    {label: "hair drier", value: "hair drier"},
    {label: "handbag", value: "handbag"},
    {label: "horse", value: "horse"},
    {label: "hot dog", value: "hot dog"},
    {label: "keyboard", value: "keyboard"},
    {label: "kite", value: "kite"},
    {label: "knife", value: "knife"},
    {label: "laptop", value: "laptop"},
    {label: "microwave", value: "microwave"},
    {label: "motorbike", value: "motorbike"},
    {label: "mouse", value: "mouse"},
    {label: "orange", value: "orange"},
    {label: "oven", value: "oven"},
    {label: "parking meter", value: "parking meter"},
    {label: "person", value: "person"},
    {label: "pizza", value: "pizza"},
    {label: "pottedplant", value: "pottedplant"},
    {label: "refrigerator", value: "refrigerator"},
    {label: "remote", value: "remote"},
    {label: "sandwich", value: "sandwich"},
    {label: "scissors", value: "scissors"},
    {label: "sheep", value: "sheep"},
    {label: "sink", value: "sink"},
    {label: "skateboard", value: "skateboard"},
    {label: "skis", value: "skis"},
    {label: "snowboard", value: "snowboard"},
    {label: "sofa", value: "sofa"},
    {label: "spoon", value: "spoon"},
    {label: "sports ball", value: "sports ball"},
    {label: "stop sign", value: "stop sign"},
    {label: "suitcase", value: "suitcase"},
    {label: "surfboard", value: "surfboard"},
    {label: "teddy bear", value: "teddy bear"},
    {label: "tennis racket", value: "tennis racket"},
    {label: "tie", value: "tie"},
    {label: "toaster", value: "toaster"},
    {label: "toilet", value: "toilet"},
    {label: "toothbrush", value: "toothbrush"},
    {label: "traffic light", value: "traffic light"},
    {label: "train", value: "train"},
    {label: "truck", value: "truck"},
    {label: "tvmonitor", value: "tvmonitor"},
    {label: "umbrella", value: "umbrella"},
    {label: "vase", value: "vase"},
    {label: "wine glass", value: "wine glass"},
    {label: "zebra", value: "zebra"},
]


export {searchableObjects}