<template>
    <fa-icon v-if="value" :icon="['fas', 'caret-up']" :class="loaded ? 'rotateUpCarrot' : ''"
       @click.stop="$emit('input', false)" style="cursor: pointer;"/>
    <fa-icon v-else :icon="['fas', 'caret-down']" :class="loaded ? 'rotateDownCarrot' : ''"
       @click.stop="$emit('input', true)" style="cursor: pointer;"/>
</template>

<script>
export default {
    name: "CarrotToggler",
    props: {
        value: {
            type: Boolean,
            required: true
        },
    },
    data: () => {
        return {
            loaded: false
        }
    },
    watch: {
        value() {
            if (!this.loaded) {
                this.loaded = true;
            }
        }
    }
}
</script>

<style scoped>
.rotateUpCarrot  {
    animation: rotateUpCarrotAnimation .6s;
}
.rotateDownCarrot  {
    animation: rotateDownCarrotAnimation .6s;
}
@keyframes rotateUpCarrotAnimation {
    from {
        transform: rotate(180deg);
    } to {
        transform: rotate(0);
    }
}
@keyframes rotateDownCarrotAnimation {
    from {
        transform: rotate(180deg);
    } to {
        transform: rotate(0);
    }
}
</style>