<template>
        <div class="row" style="text-align: center;">

            <!-- Generate Link Modal -->
            <b-modal v-model="generateLink.modal" title="Archive Link Generation" hide-footer>
                <div class="w-100 text-center" style="margin-bottom: 10px; font-weight: bold">
                    Recipents need a MTOP Satellite account with the correct permissions in order to view.
                </div>
                <div class="w-100 text-center" style="margin-bottom: 10px; font-weight: bold; color: #a20101">
                    <div>GENERATING LINKS DOES NOT SAVE THE VIDEO.</div>
                    <div>USE THE "EXPORT VIDEO" BUTTON IN ORDER TO SAVE.</div>
                </div>
                <div class="d-flex w-100 justify-content-center" style="margin-bottom: 10px">
                    <b-input-group size="sm">
                        <b-input :disabled="true" v-model="generateLink.link"/>
                        <b-input-group-append>
                            <b-button @click="copyPlaybackLink()" variant="primary">
                                <fa-icon :icon="['fas', 'copy']"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </b-modal>

            <!-- Retention -->
            <div class="col-12">
                <div v-if="retention !== 0 && retention !== null">
                    <b>Retention: </b>{{ retention }} days
                </div>
                <div v-else-if="retention === 0">
                    <b>Retention: </b>No Footage Found
                </div>
                <div v-else>
                    <b>Retention: </b>Loading... <fa-icon :icon="['fas', 'circle-notch']"/>
                </div>
            </div>

            <!-- Storage Location Select -->
            <div class="col-12" v-if="sourceDropdownOptions.length > 1" style="padding: 15px;">
                <b>Source:</b>
                <b-form-select v-model="source" :options="sourceDropdownOptions" @change="setSource"/>
            </div>

            <!-- Date Range Fields -->
            <date-range-select :retention="retention" :startDate="startDate" :endDate="endDate" :timeZone="true" :addPadding="15"
                               @startDate="startDateSelected" @endDate="endDateSelected" @isValid="isValidTime" :rangeLimit="86400000"
                               :centerDateTime="true"/>

            <!-- Export Name Input -->
            <div class="col-12">
                <b>Export Name:</b>
                <b-form-input v-model="exportName" @input="removeSpaces" :class="!exportName ? 'redBorder' : ''"/>
            </div>


            <!-- Generate Link Button -->
            <div class="col-12 d-flex justify-content-center" style="margin-top: 15px;">
                <b-button @click="generatePlaybackLink()"
                          variant="dark"
                          :disabled="!validTime"
                          size="sm">
                    Generate Archive Link
                </b-button>
            </div>

            <!-- Preview/Export Video Button -->
            <div class="col-12 d-flex justify-content-center" style="margin-bottom: 10px; margin-top: 5px; gap: 5px">
                <b-button @click="playTimeRange()"
                          variant="dark"
                          :disabled="!validTime"
                          size="sm">
                    Preview Video
                </b-button>
                <b-button :disabled="disableExportButton" @click="requestExport()" size="sm"
                          variant="dark">
                    Export {{ audioVideo === 0 ? 'Video' : 'Audio' }}
                </b-button>
            </div>

            <!-- Audio Playback -->
            <div class="col-12">
                <video v-if="audioPlayingBack" controls autoplay id="archiveaudio" style="width: 100%; height: 100%;"/>
            </div>

        </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import * as moment from 'moment';
import Multiselect from 'vue-multiselect';
import PubSub from 'pubsub-js';
import dateRangeSelect from "@/components/date-range-select.vue";

const External = 0;
const SdCard = 1;
export default {
    name: "SmartSuitePlayback",

    components: {
        datePicker,
        Multiselect,
        dateRangeSelect
    },

    props: {
        device: {
            type: Object,
        },
        sourceToken: {
            type: String,
            default: undefined
        },
        dataChannel: {
            type: String,
            default: undefined
        }
    },

    data() {
        return {
            generateLink: {
                modal: false,
                link: null
            },
            source: {storageLocation: 0, audioVideo: 0},
            retention: 0,
            exportName: null,
            pubsubs: [],
            audioDevice: false,
            audioVideo: 0,
            storageLocation: 0,
            audioPlayer: null,
            audioPlayingBack: false,
            startDate: null,
            endDate: null,
            validTime: false,
        }
    },
    beforeDestroy() {
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        });
        this.audioVideo = 0;
    },
    async created() {
        await this.device.createArchive(this.device, this.sourceToken, this.dataChannel);
        await this.device.connectToArchive(this.sourceId);
        this.audioDevice = await this.device.isAudioStream(this.sourceId);
        this.pubsubs.push(PubSub.subscribe('timeRangeSelection', async (msg, data) => {
            this.startDate = moment(data.start).format('MM/DD/YYYY h:mm:ss a Z');
            this.endDate = moment(data.end).format('MM/DD/YYYY h:mm:ss a Z');
        }));
        this.pubsubs.push(PubSub.subscribe('export_send_archive_segments', async (msg, data) => {
            if (data.storageLocation === External) {
                await this.device.createArchive(this.device, this.sourceToken, this.dataChannel);
                await this.device.connectToArchive(this.sourceId);
                if (data.segments.length > 0) {
                    await this.device.exportArchiveRequest(this.sourceId, data.segments, this.exportName, result => {
                        this.$mToast(result);
                    });
                }
            } else if (data.storageLocation === SdCard) {
                await this.device.exportSdCardRequest(this.sourceId, this.start.valueOf(), this.end.valueOf(), data.record, this.exportName, result => {
                    this.$mToast(result);
                });
            }
        }));
        this.pubsubs.push(PubSub.subscribe('retention', (msg, data) => {
            this.retention = data;
        }));
    },
    methods: {
        generatePlaybackLink() {
            let route = this.$router.resolve({
                name: 'Archives',
                query: {
                    tenantId: this.device.getTenantId(),
                    storageServer: this.device.getStorageServer(),
                    cameraName: this.device.getDeviceName(),
                    id: this.device.getDeviceId(),
                    startTime: this.start.getTime(),
                    endTime: this.end.getTime(),
                    sourceToken: this.sourceToken
                }
            })
            this.generateLink.link = new URL(route.href, window.location.origin).href;
            this.generateLink.modal = true
        },
        copyPlaybackLink() {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(this.generateLink.link).then(() => {
                    this.$mToast({
                        text: 'Copied playback link to clipboard',
                        style: 'success'
                    });
                }, () => {
                    this.$mToast({
                        title: 'Error: Failed to copy link',
                        text: 'Check console for link.',
                        style: 'error'
                    });
                });
            } else {
                this.$mToast({
                    title: 'Error: Site needs to be secure',
                    text: 'Check console for link.',
                    style: 'error'
                });
            }
        },
        startDateSelected(date) {
            this.startDate = date;
        },
        endDateSelected(date) {
            this.endDate = date;
        },
        isValidTime(boolean) {
            this.validTime = !boolean;
        },
        removeSpaces() {
            this.exportName = this.exportName.toString().replace(/[ \/]/g, '_');
        },
        playTimeRange() {
            PubSub.publish("timeRange", {startTime: this.start.valueOf(), endTime: this.end.valueOf()});
        },
        setSource() {
            if (this.storageLocation !== this.source.storageLocation) {
                this.storageLocation = this.source.storageLocation;
                PubSub.publish('storageChanged' + this.device.getDeviceId() + this.sourceToken + this.dataChannel, this.storageLocation);
            }
            if (this.audioVideo !== this.source.audioVideo) {
                this.audioVideo = this.source.audioVideo;
                this.device.setStreamType(this.sourceId, this.audioVideo);
            }
        },
        async requestExport() {
            PubSub.publish('export_request_archive_segments', {start: this.start.valueOf(), end: this.end.valueOf()});
        },
        makeDateObj(e) {
            if (e) {
                if (typeof e === "string") {
                    return moment(e, 'MM/DD/YYYY h:mm:ss a Z').toDate();
                } else {
                    return e;
                }
            } else {
                return null;
            }
        },
    },
    computed: {
        sourceDropdownOptions() {
            let result = [{value: {storageLocation: 0, audioVideo: 0}, text: 'External Video'}];
            if (this.audioDevice) {
                result.push({value: {storageLocation: 0, audioVideo: 1}, text: 'External Audio'});
            }
            if (this.device.hasSdCard()) {
                result.push({value: {storageLocation: 1, audioVideo: 0}, text: 'SD Card'});
            }
            return result;
        },
        start() {
            return this.makeDateObj(this.startDate);
        },
        end() {
            return this.makeDateObj(this.endDate);
        },
        disableExportButton() {
            return !this.exportName || !this.validTime;
        },
        sourceId() {
            return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
        }
    },
}
</script>

<style>
.redBorder {
    border: 1px solid red;
}
.errorText {
    font-size: 9px;
    color: red;
}

.arrow {
    transition: rotate .33s;
}

.rotate180degrees {
    rotate: -180deg;
}
</style>
<style src="../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>

