<template>
    <div>
        <b-modal v-model="userListModal" title="User List" hide-footer>
            <div v-for="users in userList">
                <div style="padding-left: 10px;">{{users}}</div>
            </div>
        </b-modal>


        <b-modal v-model="shareStreamModal" title="Share Info" hide-footer size="lg" no-close-on-backdrop>
            <b-tabs lazy v-model="selectedTab">
                <!-- Show Current Links -->
                <b-tab title="Links" @click="linkStatus = null;">
                    <b-card no-body>
                        <v-client-table ref="dTable" style="width: 100%" :columns="[...streamLinksOptions.columns, 'view']" :data="streamLinks"
                                        :options="{...streamLinksOptions, sortable: streamLinksOptions.columns}" :theme="theme"
                                        class="dataTable">
                            <div slot="isValid" slot-scope="props" >
                                {{capitalizeFirstLetter(props.row.isValid.toString())}}
                            </div>
                            <div slot="view" slot-scope="props" >
                                <fa-icon :icon="['fas', 'pen-to-square']" size="lg"
                                         style="display: flex; justify-content: center; padding-top: 3px; cursor: pointer"
                                         @click="viewLink(props.row)"/>
                            </div>
                            <div slot="users" slot-scope="props" >
                                <fa-icon :icon="['fas', 'user']" size="lg"
                                         style="display: flex; justify-content: center; padding-top: 3px; cursor: pointer;"
                                         @click="showUserList(props.row.users)"/>
                            </div>
                            <div slot="h__view" style="float: right">
                                <span style="float: right; margin-bottom: 0">View Link Info</span>
                            </div>
                        </v-client-table>
                    </b-card>
                    <div class="d-flex justify-content-end">
                        <b-button variant="primary" @click="createNewLink">Create New Link</b-button>
                    </div>
                </b-tab>

                <!-- Create a New Link -->
                <b-tab :title="linkStatus === 0 ? 'View Link' : linkStatus === 1 ? 'Create Link' : ''" :disabled="linkStatus === null">
                    <div style="margin-left: 3px;"><b>Share With:</b></div>
                    <b-form-select v-model="shareWith" :options="shareWithOptions" :disabled="linkStatus === 0" class="darkDisable"/>

                    <!-- Share with specific users -->
                    <div v-if="shareWith === 0">
                        <div v-if="linkStatus !== 0">
                            <div style="padding-top: 20px; margin-left: 3px;"><b>Enter UserName or Email</b></div>
                            <div class="d-flex">
                                <b-input v-model="usernameEmail"/>
                                <b-button style="margin-left: 15px;" variant="primary" @click="addUser">Add</b-button>
                            </div>
                        </div>
                        <div style="padding-top: 20px;">
                            <b-card no-body>
                                <v-client-table ref="dTable" style="width: 100%" :columns="[...shareStreamOptions.columns, linkStatus !== 0 ? 'remove' : '']" :data="shareStream.users"
                                                :options="{...shareStreamOptions, sortable: shareStreamOptions.columns}" :theme="theme"
                                                class="dataTable">
                                    <div v-if="linkStatus !== 0" slot="remove" slot-scope="props" >
                                        <fa-icon :icon="['fas', 'remove']" size="lg"
                                                 style="display: flex; justify-content: right; padding-top: 3px; cursor: pointer;"
                                                 @click="removeUser(props.row)"/>
                                    </div>
                                    <div v-if="linkStatus !== 0" slot="h__remove" style="float: right">
                                        <span style="float: right; margin-bottom: 0">Remove</span>
                                    </div>
                                </v-client-table>
                            </b-card>
                        </div>
                    </div>

                    <!-- Share with anyone -->
                    <div v-else-if="shareWith === 1">

                    </div>

                    <!-- Link Information -->
                    <div style="margin-top: 20px;">
                        <div style="margin-left: 3px;"><b>Share Link</b></div>
                        <div class="d-flex">
                            <div class="flex-grow-1"><b-input v-model="shareStream.shareLink" disabled/></div>
                            <b-button size="sm" variant="dark" style="margin-left: 5px;" title="Copy Link" @click="copyLink">
                                <fa-icon :icon="['fas', 'clipboard']" size="lg" fixed-width/>
                            </b-button>
                        </div>
                    </div>

                    <!-- Expiration Information -->
                    <div style="margin-top: 20px;">

                        <!-- Expiration -->
                        <div style="margin-left: 3px;"><b>Expiration</b></div>
                        <b-form-select v-model="expiration" :options="expirationOptions" :disabled="linkStatus === 0" class="darkDisable"/>

                        <!-- Valid Link Limitations -->
                        <b-row style="margin-top: 10px;">
                            <b-col>
                                <div style="margin-left: 3px;"><b>Time Selection</b></div>
                                <b-form-select class="darkDisable" v-model="timeSelection" :options="timeSelectionOptions" @change="calculateValidUntilDate" :disabled="checkExpirationValidation()"/>
                            </b-col>
                            <b-col sm="8">
                                <div style="margin-left: 3px;"><b>Valid Until</b></div>
                                <date-picker v-model="shareStream.validUntil" :config="dateConfig" :readonly="checkDateValidation()"/>
                            </b-col>
                        </b-row>
                    </div>

                    <!-- Date Created -->
                    <div style="margin-top: 20px;" class="d-flex justify-content-between">
                        <div v-if="shareStream.created_date === null"></div>
                        <div v-else style="margin-left: 3px;"><b>Date Created:</b> {{shareStream.created_date}}</div>
                        <div v-if="linkStatus !== 0"><b-button size="sm" variant="primary" @click="createShareLink" :disabled="checkValidShareLink()">Create Share Link</b-button></div>
                    </div>

                </b-tab>

            </b-tabs>
        </b-modal>

        <!-- Share Streaming Button on Sidebar -->
        <div style="padding: 5px">
            <div class="rounded w-100" style="padding: 5px; background-color: #151515">
                <div class="row" style="text-align: center;">
                    <div class="col-12">
                        <div>
                            <b-button variant="primary" @click="openShareStreamModal"><b>Share Stream</b></b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ClientTable} from 'vue-tables-2'
import Vue from "vue";
import datePicker from 'vue-bootstrap-datetimepicker';
Vue.use(ClientTable)

export default {
    name: "SmartSuiteVideoShare",
    components: {
        datePicker
    },
    props: {
        device: Object,

    },
    data() {
        return {
            selectedTab: 0,
            userListModal: false,
            userList: [],
            shareStreamModal: false,
            usernameEmail: '',
            linkStatus: null,
            dateConfig: {
                format: 'MM/DD/YYYY h:mm:ss A',
                useCurrent: false,
                minDate: new Date(Date.now())
            },
            shareWithOptions: [
                {value: 0, text: 'Specific Users'},
                {value: 1, text: 'AnyOne With Valid Link'}
            ],
            shareWith: 0,
            expirationOptions: [
                {value: 0, text: 'Single Use'},
                {value: 1, text: 'Single Use Until Specific Date'},
                {value: 2, text: 'Unlimited Uses'},
                {value: 3, text: 'Unlimited Uses Until Specific Date'}
            ],
            expiration: 0,
            timeSelectionOptions: [
                {value: 3600*1000, text: '1 hour'},
                {value: 3600*1000*3, text: '3 hours'},
                {value: 3600*1000*6, text: '6 hours'},
                {value: 3600*1000*12, text: '12 hours'},
                {value: 3600*1000*24, text: '1 day'},
                {value: 3600*1000*48, text: '2 days'},
                {value: 3600*1000*72, text: '3 days'},
                {value: 3600*1000*24*7, text: '1 week'},
                {value: 0, text: 'Custom Time'},
            ],
            timeSelection: null,
            streamLinks: [
                {created_date: 'May 16, 2022', isValid: true, _id: 0, createdUser: 'admin', users: ['I can be a user', 'test'], expirationDate: 'May 5, 2023'},
                {created_date: 'March 18, 2022', isValid: true, _id: 1, createdUser: 'admin', users: ['test2', 'test 14'], expirationDate: 'April 16, 2023'},
                {created_date: 'October 5, 2022', isValid: false, _id: 2, createdUser: 'admin', users: ['a', 'b', 'c'], expirationDate: 'November 8, 2022'},
            ],
            shareStream: {
                users: [
                    {userName: 'JoeMama', email: '11111', _id: 0},
                    {userName: 'ZackSpark', email: '222222', _id: 1},
                    {userName: 'SpirtHommy', email: '3333333', _id: 2},
                    {userName: 'RalphYurtish', email: '444444444444', _id: 3},
                ],
                shareLink: 'youtube.com',
                validUntil: null,
                created_date: 'about now'
            },
            streamLinksOptions: {
                columns: ['created_date', 'isValid', 'createdUser', 'expirationDate', 'users'],
                orderBy: {column: 'name', ascending: true},
                headings: {
                    created_date: 'Created Date',
                    isValid: 'Valid',
                    createdUser: 'Created By',
                    expirationDate: 'Expiration',
                    users: 'Users'
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 5,
                skin: 'table table-striped table-hover',
                filterable: false
            },
            shareStreamOptions: {
                columns: ['userName', 'email'],
                orderBy: {column: 'name', ascending: true},
                headings: {
                    userName: 'User Name',
                    email: 'Email',
                    remove: 'Remove',
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 5,
                skin: 'table table-striped table-hover',
                filterable: false
            },
            theme: 'bootstrap4',
        }
    },
    created() {
    },
    methods: {
        clearShareStream() {
            this.shareStream = {
                users: [],
                shareLink: '',
                validUntil: null,
                created_date: null
            }
        },
        openShareStreamModal() {
            this.shareStreamModal = true;
        },
        showUserList(users) {
            this.userList = users;
            this.userListModal = true;
        },
        addUser() {
            //get info for specific user and add
            //get new list of users for link
            this.usernameEmail = '';
        },
        removeUser(user) {},
        copyLink() {
            navigator.clipboard.writeText(this.shareStream.shareLink);
        },
        async createNewLink() {
            //0 view link - 1 create link
            await this.setLinkStatus(1);
            this.selectedTab = 1;
        },
        setLinkStatus(status) {
            this.linkStatus = status;
        },
        createShareLink() {
            //send this.shareStream
            //get new list of links
            //go to view link
            this.setLinkStatus(0);
        },
        checkValidShareLink() {
            //if sharing with users and no users are added
            if (this.shareWith === 0 && this.shareStream.users.length === 0) {
                return true;
            }
            //if expiration needs a date and a date hasn't been selected
            if ((this.expiration === 1 || this.expiration === 3) && this.timeSelection === null) {
                return true;
            }
            return false;
        },
        async viewLink(link) {
            //get info for specific link
            //fill this.shareStream with info
            await this.setLinkStatus(0);
            this.selectedTab = 1;
        },
        resetOptions() {
            this.linkStatus = null;
            this.selectedTab = 0;
            this.shareWith = 0;
            this.expiration = 0;
            this.timeSelection = null;
        },
        calculateValidUntilDate() {
            this.shareStream.validUntil = new Date(Date.now() + this.timeSelection);
        },
        checkExpirationValidation() {
            if (this.linkStatus === 0) {
                return true;
            }
            switch (this.expiration) {
                case 0: case 2:
                    return true;
                case 1: case 3:
                    return false;
                default:
                    return true;
            }
        },
        checkDateValidation() {
            if (this.linkStatus === 0) {
                return true;
            }
            return this.timeSelection !== 0;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    },
    watch: {
        shareStreamModal() {
            if (this.shareStreamModal === false) {
                this.resetOptions();
            }
        },
        timeSelection() {

        },
        expiration() {

        },
    },
}
</script>

<style>
.darkDisable:disabled {
    background-color: #1a1a1a !important;
}

</style>