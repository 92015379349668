<template>
    <div class="rounded w-100" style="padding: 5px 10px 5px 10px; background-color: #151515" :class="isBlurred === true ? 'blurEffect' : ''">
        <div class="w-100 d-flex align-items-center justify-content-between">
            <b>{{title}}</b>
            <div>
                <slot name="button"></slot>
                <CarrotToggler v-model="expanded"/>
            </div>
        </div>

        <b-collapse v-model="expanded">
            <slot/>
        </b-collapse>
    </div>
</template>

<script>
import CarrotToggler from "@/components/CarrotToggler.vue";
import PubSub from "pubsub-js";

export default {
    name: "VideoAsideTemplate",
    components: {CarrotToggler},
    props: {
        title: {
            type: String,
            required: true
        },
        asideKey: {
            type: String
        }
    },
    data () {
        return {
            expanded: true,
            isBlurred: false,
            pubsubs: []
        }
    },
    created() {
        this.pubsubs.push(PubSub.subscribe('toggleBlur', (msg, data) => {
            if (data !== this.asideKey) {
                this.isBlurred = true;
            }
        }));
        this.pubsubs.push(PubSub.subscribe('toggleAll', () => {
            this.isBlurred = false;
        }));

    },
    beforeDestroy() {
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        })
    },
    methods: {
        buttonPressed() {
            PubSub.publish(this.buttonPubSubInvocationString);
        }
    }
}
</script>

<style scoped>
    .blurEffect {
        filter: blur(5px);
    }
</style>