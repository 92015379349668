<template>
    <div>

        <div class="rounded w-100" style="background-color: #151515;">
            <!-- Filters -->
            <b-collapse id="collapse-1" v-model="filterOpen" style="width: 100%; height: 200px;">
                <b-card no-body style="padding: 3px; margin-bottom: 3px; background-color: #151515; border: none;">
                    <div style="padding-bottom: 5px;">Event Type:</div>
                    <div class="d-flex flex-wrap">
                        <b-radio-group v-model="selectedSearchEventType">
                            <b-radio :value="eventFilter.eventType" v-for="eventFilter in eventFilters" :key="'filterValue'+eventFilter.eventType">{{eventFilter.name}}</b-radio>
                        </b-radio-group>
                    </div>
                    <hr color="white">
                    <div style="padding-bottom: 5px;">Sort Events:</div>
                    <b-radio-group v-model="newestFirst" style="padding-bottom: 5px;">
                        <b-radio :value="true">Newest</b-radio>
                        <b-radio :value="false">Oldest</b-radio>
                    </b-radio-group>
                    <div>Events After Date</div>
                    <div @focusin="blurComponents" @focusout="unblurComponents" class="datePickerScroll">
                        <date-picker v-model="filterStartTime" :config="configOptions" style="margin-bottom: 8px"/>
                    </div>
                    <div>Events Before Date</div>
                    <div @focusin="blurComponents" @focusout="unblurComponents" class="datePickerScroll">
                        <date-picker v-model="filterEndTime" :config="configOptions" style="margin-bottom: 8px"/>
                    </div>

                </b-card>
            </b-collapse>

            <!-- Events -->
            <div style="max-height: 500px; overflow-y: auto;" :class="blurEvents === true ? 'blurEffect' : ''">
                <div v-if="events.length > 0">
<!--                    <RecycleScroller style="height: 100%; max-height: 500px;"
                                     :items="events"
                                     :itemSize="75"
                                     key-field="id"
                                     v-slot="{ item, index }">
                        <div style="padding: 4px 5px; cursor: pointer;" @click="goToEvent(item)" :title="'Go To '+ new Date(item.timeStamp).toLocaleString()">
                            <div class="rounded w-100" style="padding: 5px 0; background-color: #2e2e2e">
                                <div class="d-flex justify-content-between">
                                    <div style="padding: 0 5px;"><fa-icon :icon="['fas', determineIcon(item.eventType)]" :class="determineColor(item.eventType)"/> {{getEventTypeName(item.eventType)}} Event</div>
                                </div>
                                <div style="padding: 0 5px; font-size: 12px;">Start Time: {{new Date(item.timeStamp).toLocaleString()}}</div>
                                <div style="padding: 0 5px; font-size: 12px;">End Time: {{new Date(item.endTime).toLocaleString()}}</div>
                            </div>
                        </div>

                    </RecycleScroller>-->
                    <div v-for="(event, index) in events" :key="'event'+event.id">
                        <div v-if="index === 0 && event.pinned !== true">
                            <div class="d-flex justify-content-center"><b>{{new Date(event.timeStamp).toLocaleDateString()}}</b></div>
                        </div>
                        <div v-else-if="index !== 0 && event.pinned !== true">
                            <div v-if="events[index-1].pinned === true ||
                                   (newestFirst === false && Math.floor(events[index-1].timeStamp/(86400*1000)) < Math.floor(event.timeStamp/(86400*1000))) ||
                                   (newestFirst === true && Math.floor(events[index-1].timeStamp/(86400*1000)) > Math.floor(event.timeStamp/(86400*1000)))">
                                <div class="d-flex justify-content-center"><b>{{new Date(event.timeStamp).toLocaleDateString()}}</b></div>
                            </div>
                        </div>
                        <div style="padding: 4px 5px; cursor: pointer;" @click="goToEvent(event)" :title="'Go To '+ new Date(event.timeStamp).toLocaleString()">
                            <div class="rounded w-100" style="padding: 5px 0; background-color: #2e2e2e">
                                <div class="d-flex justify-content-between">
                                    <div style="padding: 0 5px;"><i :class="determineIcon(event.eventType)"/> {{getEventTypeName(event.eventType)}} Event</div>
                                </div>
                                <div style="padding: 0 5px; font-size: 12px;">Start Time: {{new Date(event.timeStamp).toLocaleString()}}</div>
                                <div style="padding: 0 5px; font-size: 12px;">End Time: {{new Date(event.endTime).toLocaleString()}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="d-flex justify-content-center">No {{getEventTypeName(selectedSearchEventType)}} Events</div>
            </div>
        </div>

    </div>
</template>

<script>
import PubSub from "pubsub-js";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

const Unknown = 0;
const SystemReady = 1;
const Temperature = 2;
const NetworkLost = 3;
const VidLiveStreamAccessed = 4;
const AbrStatus = 5;
const IoPort = 6;
const VirtualPort = 7;
const VirtualIo = 8;
const MediaClip = 9;
const StorageAlert = 10;
const StorageDisruption = 11;
const StorageRecording = 12;
const AudioTrigger = 13;
const MotionTrigger = 14;
const MotionRecording = 15;
const RingPowerExceeded = 16;
const DayNightVision = 17;

export default {
    name: "SmartSuiteEvents",
    props: {
        device: {
            type: Object
        },
        sourceToken: {
            type: String,
            default: '0'
        },
        dataChannel: {
            type: String,
            default: undefined
        }
    },
    components: {
        datePicker,
        RecycleScroller
    },
    data() {
        return {
            events: [],
            eventFilters: [
                {eventType: MotionTrigger, active: true, name: 'Motion'},
                {eventType: AudioTrigger, active: false, name: 'Audio'}
            ],
            selectedSearchEventType: MotionTrigger,
            filterStartTime: null,
            filterEndTime: null,
            newestFirst: true,
            newEvents: [],
            configOptions: {
                format: 'MM/DD/YYYY H:mm:ss',
                useCurrent: true,
                maxDate: new Date(),
                minDate: 0,
                showClear: true,
                showClose: true
            },
            blurEvents: false,
            filterOpen: false,
            pubsubs: []
        }
    },
    async created() {
        await this.device.createEventHub(this.device, this.sourceToken, this.dataChannel);
        await this.device.connectToEvents(this.playerId);
        await this.device.createArchive(this.device, this.sourceToken, this.dataChannel);
        await this.device.connectToArchive(this.playerId);

        this.device.getRetention(this.playerId, async retentionTime => {
            this.filterStartTime = new Date(retentionTime);
            await this.getEvents();
        });
        this.device.setEventHubListener(this.playerId, async event => {
           if (event.eventType !== Unknown &&
               event.sourceSerialNumber === this.device.getDeviceId() &&
               JSON.parse(event.rawEvent).message.source.Source === this.sourceToken &&
               event.eventState === '0' &&
               this.selectedSearchEventType === MotionTrigger) {
               await this.device.getDeviceEventsByType(this.playerId, this.selectedSearchEventType, Date.now() - 120000, Date.now(), events => {
                   if (events.length > 0) {
                       let newEvent = Object.assign(events[events.length - 1], {
                           timeStampHuman: new Date(events[events.length - 1].timeStamp).toLocaleString(),
                           startTimeHuman: new Date(events[events.length - 1].startTime).toLocaleString(),
                           endTime: events[events.length - 1].timeStamp + 25000,
                           rawEvent: JSON.parse(events[events.length - 1].rawEvent),
                           type: this.getEventTypeName(events[events.length - 1].eventType)
                       });
                       if (this.newestFirst === true) {
                           if (this.events[0].id !== newEvent.id) {
                               this.events.splice(0, 0, newEvent);
                           }
                       } else {
                           if (this.events[this.events.length - 1].id !== newEvent.id) {
                               this.events.push(newEvent);
                           }
                       }
                   }
               });
           }
        });
        this.pubsubs.push(PubSub.subscribe('getEventsList'+this.playerId, (msg, data) => {
            let array = this.events.filter(event => {
                if (event.timeStamp >= data.startTime && event.timeStamp <= data.endTime) {
                    return event;
                }
            });
            PubSub.publish('sendEventList'+this.playerId, array);
        }));
        this.pubsubs.push(PubSub.subscribe('toggleEventsFilter'+this.playerId, (msg, data) => {
            this.filterOpen = !this.filterOpen;
        }));
    },
    mounted() {
        window.addEventListener("blur", this.unblurComponents);
    },
    beforeDestroy() {
        window.removeEventListener("blur", this.unblurComponents);
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        });
    },
    methods: {
        async getEvents() {
            await this.device.getDeviceEventsByType(this.playerId, this.selectedSearchEventType, this.filterStartTime, this.filterEndTime, events => {
                let sortedEvents = this.timeSort(events, true);
                let combinedEvents = [], tempStartEvent = null, tempIndex = 0;
                if (sortedEvents.length > 0) {
                    for (let i = 0; i < sortedEvents.length; i++) {
                        if (i === 0) {
                            tempStartEvent = sortedEvents[i];
                            tempIndex = combinedEvents.length;
                            combinedEvents.push(sortedEvents[i]);
                        }
                        //if not the first event
                        if (sortedEvents[i-1] !== undefined) {
                            //if the next event is more than 25 seconds after the previous one
                            if (sortedEvents[i-1].timeStamp < sortedEvents[i].timeStamp - 25000) {
                                //get a stop time from the last event in the chain
                                combinedEvents[tempIndex].endTime = sortedEvents[i-1].timeStamp + 25000;
                                tempStartEvent = sortedEvents[i];
                                tempIndex = combinedEvents.length;
                                combinedEvents.push(sortedEvents[i]);
                                //if last in array
                                if (sortedEvents[i+1] === undefined) {
                                    combinedEvents[tempIndex].endTime = sortedEvents[i].timeStamp + 25000;
                                }
                            } else if (sortedEvents[i+1] === undefined) {
                                combinedEvents[tempIndex].endTime = sortedEvents[i].timeStamp + 25000;
                            }
                        }
                    }
                }
                this.events = this.timeSort(combinedEvents.map(event => {
                    return Object.assign(event, {
                        timeStampHuman: new Date(event.timeStamp).toLocaleString(),
                        startTimeHuman: new Date(event.startTime).toLocaleString(),
                        rawEvent: JSON.parse(event.rawEvent),
                        type: this.getEventTypeName(event.eventType)
                    });
                }));
            });
        },
        blurComponents() {
            PubSub.publish('toggleBlur', 'events');
            this.blurEvents = true;
        },
        unblurComponents() {
            PubSub.publish('toggleAll');
            this.blurEvents = false;
        },
        goToEvent(event) {
            //this goes to SmartSuiteTimeline
            PubSub.publish("timeRange", {startTime: event.timeStamp, endTime: event.endTime});
            //this goes to SmartSuitePlayback
            PubSub.publish("timeRangeSelection", {start: event.timeStamp, end: event.endTime});
        },
        determineIcon(eventType) {
            switch (eventType) {
                case MotionTrigger:
                    return 'forward';
                case AudioTrigger:
                    return 'bell';
                default:
                    return 'bookmark';
            }
        },
        determineColor(eventType) {
            switch (eventType) {
                case MotionTrigger:
                    return 'color-blue';
                case AudioTrigger:
                    return 'color-yellow';
                default:
                    return 'color-green';
            }
        },
        getEventTypeName(eventType) {
            switch (eventType) {
                case MotionTrigger:
                    return 'Motion';
                case AudioTrigger:
                    return 'Audio';
                default:
                    return 'Other';
            }
        },
        timeSort(events, createdSort) {
            if (this.newestFirst === false || createdSort === true) {
                return events.sort((a, b) => {
                    if (a.timeStamp > b.timeStamp)
                        return 1;
                    if (a.timeStamp < b.timeStamp)
                        return -1;
                    return 0;
                });
            } else {
                return events.sort((a, b) => {
                    if (a.timeStamp < b.timeStamp)
                        return 1;
                    if (a.timeStamp > b.timeStamp)
                        return -1;
                    return 0;
                });
            }
        },
    },
    computed: {
        playerId() {
            return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
        }
    },
    watch: {
        newestFirst() {
            this.timeSort(this.events);
        },
        selectedSearchEventType() {
            this.getEvents();
        },
        filterStartTime() {
            this.getEvents();
        },
        filterEndTime() {
            this.getEvents();
        }
    }
}
</script>

<style scoped>
    .color-blue {
        color: #00ffff;
    }
    .color-yellow {
        color: #ffff00;
    }
    .color-green {
        color: #00ff00;
    }
    .blurEffect {
        filter: blur(5px);
    }
</style>
