<template>
    <div :style="videoAsideBool ? 'padding-top: 3px; margin: 0 -30px' : ''">

        <!-- Video Aside -->
        <AppAside fixed style="margin-top: -73px">
            <videoAside :name="selectedDevice ? selectedDevice.getDeviceName() : null" sourceToken="0"
                        :bool="videoAsideBool" :device="selectedDevice" :playback="true" :archiveOnly="true"/>
        </AppAside>

        <!-- Select Days Modal -->
        <b-modal v-model="largeExports.daysModal" title="Select Days">

            <!-- Export Name -->
            <div class="mFieldsetTitle"> Export Name </div>
            <div class="mFieldset">
                <div style="margin-top: 5px">
                    <b-input v-model="largeExports.exportName" size="sm" :maxlength="30" :style="largeExports.exportName ? '' : 'border: 1px solid rgb(248, 108, 107)'"/>
                </div>
                <div style="font-size: 10px; margin-bottom: -10px">
                    {{largeExports.exportName.length}} / 30
                </div>
            </div>

            <!-- Days -->
            <div class="mFieldsetTitle d-flex align-items-center">
                <h5 style="margin-bottom: 0; margin-right: 3px;"><b>Days</b></h5>&nbsp;
                <b-badge class="badge-success" @click="largeExports.selectedDays.push('')"
                         style="height: fit-content; align-self: center; cursor: pointer;">
                    <fa-icon :icon="['fas', 'plus']"/> Add
                </b-badge>
            </div>
            <div class="mFieldset datePickerTheme">
                <b-input-group v-for="(day, index) in largeExports.selectedDays" :key="'large_export_'+day" style="margin-top: 5px" size="sm">
                    <b-datepicker v-model="largeExports.selectedDays[index]" :max="largeExports.maxDate" value-as-date
                                  :style="largeExports.selectedDays[index] && !duplicateDates.includes(index) ? '' : 'border: 1px solid rgb(248, 108, 107)'"/>
                    <b-input-group-append v-if="largeExports.selectedDays.length > 1 || (largeExports.selectedDays.length === 1 && largeExports.selectedDays[0] !== '')">
                        <b-button style="width: 40px" variant="danger" @click="removeDate(index)">
                            <fa-icon :icon="['fas', 'close']"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <div style="margin-top: 5px; color: rgb(248, 108, 107); font-size: 10px;" v-if="duplicateDates.length > 0">
                    Remove Duplicate Dates
                </div>
            </div>

            <!-- Selected Archives -->
            <div class="mFieldsetTitle"> Selected Archives </div>
            <div class="mFieldset">
                <div style="border: 1px solid black; margin-top: 5px; border-radius: 5px; overflow: hidden">
                    <table style="border-collapse: collapse">
                        <tr style="font-weight: bolder; border-bottom: 1px solid black">
                            <td>Tenant</td>
                            <td>Device Name</td>
                            <td>Storage Name</td>
                        </tr>
                        <tr v-for="archive in largeExports.selectedArchives">
                            <td>{{archive.tenant}}</td>
                            <td>{{archive.deviceFriendlyName}}</td>
                            <td>{{archive.streamFriendlyName}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="largeExports.daysModal = false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="submitLargeExport()"
                          :disabled="duplicateDates.length > 0 || largeExports.selectedDays.includes('') || !largeExports.exportName">
                    Submit
                </b-button>
            </template>

        </b-modal>

        <!-- Main Page -->
        <b-card v-if="videoAsideBool === false" style="height: calc(100vh - 200px);" no-body>

            <!-- Header -->
            <div slot="header" class="d-flex justify-content-between align-items-center">
                <h5 style="margin-bottom: 0"><b>Video Archives</b></h5>
                <b-button variant="primary" size="sm" @click="openLargeExports" v-if="!largeExports.isActive">
                    New Large Export
                </b-button>
                <b-input-group v-else size="sm" style="width: fit-content">
                    <b-input-group-prepend>
                        <b-input-group-text style="background-color: black">
                            Large Export:
                        </b-input-group-text>
                    </b-input-group-prepend>
                    <b-input-group-append>
                        <b-button variant="danger" @click="largeExports.isActive = false" size="sm">
                            Quit
                        </b-button>
                        <b-button variant="success" @click="largeExports.daysModal = true" size="sm"
                                  :disabled="largeExports.selectedArchives.length < 1">
                            Select Days
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>

            <!-- Table -->
            <div style="overflow-y: auto; overflow-x: hidden; height: 100%; padding: 20px;">
                <v-client-table ref="dTable" style="width: 100%;" :columns="largeExports.isActive ? ['check', ...columns] : columns" :data="archiveList"
                                :options="options" :theme="options.theme"
                                class="dataTable">
                    <div slot="tenant" slot-scope="props">
                        {{getTenantName(props.row.tenant)}}
                    </div>
                    <div slot="streamFriendlyName" slot-scope="props">
                        <span @click="selectArchive(props.row)" style="cursor: pointer;">{{props.row.streamFriendlyName}}</span>
                    </div>
                    <div slot="viewArchive" slot-scope="props" class="tableButtonContainer">
                        <b-button class="tableButton" @click="selectArchive(props.row)">
                            <fa-icon :icon="['far', 'square-caret-right']" size="lg"/>
                        </b-button>
                    </div>
                    <div slot="h__viewArchive" style="float: right">View Archive</div>
                    <div slot="h__check"/>
                    <div slot="check" slot-scope="props">
                        <b-checkbox v-model="largeExports.selectedArchives" :value="props.row"/>
                    </div>
                </v-client-table>
            </div>

        </b-card>

        <!-- Video Preview -->
        <div v-else :id="'stream_settings_container_' + selectedDevice.getDeviceId() + '0' + undefined"
             :class="!videoAsideBool ? '' : 'd-flex flex-column align-content-stretch'" style="margin-top: -28px;"
             :style="!videoAsideBool ? 'display: none' : fullscreen ? 'height: 100vh; width: 100vw;' : 'height: calc(100vh - 150px); width: 100%'">
            <div id="streamSettings" class="flex-grow-1 flex-shrink-1 d-flex" style="min-height: 0">
                <div class="flex-fill d-flex noHighlight" style="position: relative; height: 100%; width: 100%"
                     :id="'video-container-' + selectedDevice.getDeviceId() + '0' + undefined">
                    <stream :device="selectedDevice" :sourceToken="'0'" :dataChannel="undefined" :quality="selectedDevice.quality"
                            class="d-flex flex-fill" :id="selectedDevice.getDeviceId() + '0' + undefined" :archiveOnly="true"/>
                    <PlayerControls :device="selectedDevice" :isStreamOptions="!videoAsideBool"
                                    class="flex-fill h-100 w-100" :sourceToken="'0'" :quality="selectedDevice.quality"
                                    :archiveOnly="true" :playback="true" style="position: absolute; z-index: 2"
                                    :dataChannel="undefined" @close="closeArchive"
                                    containerIDPrefix="video-container-" @closeStreamOptions="selectedDevice = null"/>
                </div>
            </div>
            <SmartSuiteTimeline :sourceToken="'0'" :dataChannel="undefined" :device="selectedDevice" :archiveOnly="true"/>
        </div>

    </div>
</template>

<script>
import tenantStore from "@/store/tenantStore";
import {Aside as AppAside} from "./template_files/navbarJazz";
import videoAside from "./video_wall/videoAside";
import stream from "./video_wall/stream";
import SmartSuiteDevice from "@/shared/smartsuite_services/smartsuite_device";
import SmartSuiteTimeline from "./video_wall/SmartSuiteTimeline";
import {ClientTable} from 'vue-tables-2'
import Vue from "vue";
import PlayerControls from "./video_wall/PlayerControls.vue";
import SmartSuiteVideoSearch from "@/components/SmartSuiteVideoSearch.vue";
import SmartSuiteArchive from "@/shared/smartsuite_services/smartsuite_archive";
import PubSub from "pubsub-js";

Vue.use(ClientTable)


export default {
    name: "VideoArchives",
    components: {
        AppAside,
        videoAside,
        stream,
        SmartSuiteTimeline,
        PlayerControls,
        SmartSuiteVideoSearch
    },
    data() {
        return {
            largeExports: {
                isActive: false,
                selectedArchives: [],
                selectedDays: [''],
                daysModal: false,
                maxDate: null,
                exportName: '',
            },
            route: {
                isInfo: false,
                foundMatch: false,
                isDone: false,
                pubSub: null,
                tenantsDone: 0,
                isError: false
            },
            fullscreen: false,
            archiveList: [],
            ssDevices: [],
            selectedArchive: null,
            selectedDevice: undefined,
            videoAsideBool: false,
            tenants: [],
            columns: ['tenant', 'deviceFriendlyName', 'streamFriendlyName', 'viewArchive'],
            options: {
                orderBy: {column: 'deviceFriendlyName', ascending: true},
                headings: {
                    'tenant': 'Tenant',
                    'deviceFriendlyName': 'Device Name',
                    'streamFriendlyName': 'Storage Name',
                    'viewArchive': 'View Storage'
                },
                sortable: ['tenant', 'deviceFriendlyName', 'streamFriendlyName'],
                filterable: ['tenant', 'deviceFriendlyName', 'streamFriendlyName'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
                theme: 'bootstrap4',
            },
        }
    },
    created() {
        document.addEventListener('fullscreenchange', function() {
            this.fullscreen = !!document.fullscreenElement;
        }.bind(this));
        if (this.$route.query.startTime) {
            this.route.isInfo = true;
        }
        this.getTenant();
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.route.pubSub);
    },
    methods: {
        submitLargeExport() {
            this.largeExports.isActive = false;
            this.largeExports.daysModal = false;
            let archivesSeperated = this.largeExports.selectedArchives.reduce((acc, archive) => {
                if (acc[archive.tenantId]) {
                    acc[archive.tenantId].push(archive);
                } else {
                    acc[archive.tenantId] = [archive];
                }
                return acc;
            }, {});
            this.tenants.forEach(tenant => {
                if (archivesSeperated[tenant._id]) {
                    tenant.ssArchive.queueLargeExport(
                        this.largeExports.exportName,
                        archivesSeperated[tenant._id],
                        this.largeExports.selectedDays,
                        result => {
                            this.$mToast(result);
                        }
                    );
                }
            });
        },
        removeDate(index) {
            if (this.largeExports.selectedDays.length === 1) {
                this.$set(this.largeExports.selectedDays, '0', '');
            } else {
                this.largeExports.selectedDays.splice(index, 1);
            }
        },
        openLargeExports() {
            const now = new Date();
            this.largeExports.maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            this.largeExports.selectedArchives = [];
            this.largeExports.selectedDays = [''];
            this.largeExports.isActive = true;
            this.largeExports.exportName = '';
        },
        closeArchive() {
            this.videoAsideBool = false;
            this.selectedDevice = null;
            this.selectedArchive = null;
        },
        async selectArchive(device) {
            if (this.selectedArchive === device) {
                this.videoAsideBool = !this.videoAsideBool;
            } else {
                this.videoAsideBool = true;
            }
            this.selectedArchive = device;
            this.selectedDevice = this.ssDevices.find(ssDevice => {
                return ssDevice.getDeviceId() === device._id;
            });
            let sourceToken = this.$route.query.sourceToken
            this.selectedDevice.createArchive(this.selectedDevice, sourceToken, undefined);
            if (this.$route.query.startTime && !this.route.isDone) {
                this.route.isDone = true;
                this.route.pubSub = PubSub.subscribe('retention', (msg, data) => {
                    let start = parseInt(this.$route.query.startTime);
                    let end = parseInt(this.$route.query.endTime);
                    let retention = data
                    let max = Date.now();
                    let min = max - (retention * 86400 * 1000);
                    if (!end || !start || start < min || end > max) {
                        this.$mToast({
                            text: 'Error: Time-range in URL is outside retention',
                            style: 'error',
                            timeout: false
                        })
                    } else {
                        PubSub.publish("timeRangeSelection", {start: start, end: end});
                        PubSub.publish("timeRange", {startTime: start, endTime: end});
                    }
                    PubSub.unsubscribe(this.route.pubSub)
                });
            }
        },
        getTenant() {
            tenantStore.dispatch('getTenants').then(response => {
                this.tenants = response;
                if (this.route.isInfo && !response.find(_ => _._id === this.$route.query.tenant)) {
                    this.route.isError = true;
                    this.$mToast({
                        text: 'Error: Tenant \'' + this.$route.query.tenant + '\' not found.',
                        style: 'error',
                        timeout: 5000
                    })
                }
                this.tenants.forEach(tenant => {
                    this.getDevices(tenant);
                });
            });
        },
        getTenantName(tenantId) {
            return this.tenants.find(tenant => tenant._id === tenantId).tenantName;
        },
        async getDevices(tenant) {
            let ssDeviceFound = null;
            let tenantMatch = false;
            let deviceNameFound = false;
            tenant.ssArchive = await new SmartSuiteArchive(null, null, null, tenant);
            await tenant.ssArchive.connect();
            await tenant.ssArchive.getStorageServers(async storageServers => {
                tenant.storageServers = storageServers;
                if (this.route.isInfo && !this.route.isError
                    && tenant._id === this.$route.query.tenant) {
                    tenantMatch = true;
                    if (!storageServers.find(_ => _ === this.$route.query.storageServer)) {
                        this.route.isError = true;
                        this.$mToast({
                            text: 'Error: Storage Server \'' + this.$route.query.storageServer + '\' not found.',
                            style: 'error',
                            timeout: 5000
                        });
                    }
                }
                await tenant.ssArchive.streamAllDeviceStoresAsync(storageServers, async response => {
                    if (response === 'done') {
                        this.route.tenantsDone++;
                        if (ssDeviceFound && this.route.foundMatch) {
                            await this.selectArchive(ssDeviceFound);
                        } else if (this.route.isInfo && tenantMatch && !this.route.foundMatch && !this.route.isError) {
                            if (deviceNameFound) {
                                this.$mToast({
                                    text: 'Error: Storage name \'' + this.$route.query.id + '\' not found.',
                                    style: 'error',
                                    timeout: 5000
                                });
                            } else {
                                this.$mToast({
                                    text: 'Error: Device \'' + this.$route.query.cameraName + '\' not found.',
                                    style: 'error',
                                    timeout: 5000
                                });
                            }
                        }
                        return;
                    }
                    let ssDevices = [];
                    response = response.map(archive => {
                        if (this.route.isInfo && !this.route.foundMatch && tenant._id === this.$route.query.tenant
                            && this.$route.query.storageServer === archive.storageServer
                            && archive.deviceFriendlyName === this.$route.query.cameraName) {
                            deviceNameFound = true;
                            if ((!this.$route.query.id || (archive.storeName === this.$route.query.id))) {
                                this.route.foundMatch = true;
                                ssDeviceFound = archive;
                            }
                        }
                        return Object.assign(archive, {
                            _id: archive.storeName,
                            tenantId: tenant._id,
                            tenant: tenant._id,
                            url: config.VUE_APP_MESSAGESERVER,
                            port: null,
                            archive: true,
                            friendlyName: archive.deviceFriendlyName,
                            streamServer: config.VUE_APP_STREAMSERVER + 'archive/'
                        });
                    });
                    for (let i = 0; i < response.length; i++) {
                        ssDevices[i] = await new SmartSuiteDevice(response[i]);
                        ssDevices[i].setArchive();
                    }
                    this.ssDevices = this.ssDevices.concat(ssDevices);
                    this.archiveList = this.archiveList.concat(response);
                })
            });
        },
    },
    computed: {
        duplicateDates() {
            if (!this.largeExports.isActive || !this.largeExports.daysModal) return [];
            const indexMap = {};
            return this.largeExports.selectedDays.reduce((duplicates, item, index) => {
                if (item !== '') {
                    if (indexMap[item] === undefined) {
                        indexMap[item] = index;
                    } else {
                        duplicates.push(indexMap[item]);
                        duplicates.push(index);
                    }
                }
                return duplicates;
            }, []);
        }
    }
}
</script>

<style scoped>
.mainContainer {
    height: calc(100vh - 151px);
    margin: -24px -30px 0 -30px
}
.tableButton {
    height: 29px !important;
    width: 29px !important;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tableButton:not(:hover) {
    background-color: #131313 !important;
}
.tableButtonContainer {
    margin: -12px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    transform: translateY(6px)
}

table {
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 8px;
}

th {
    background-color: #4caf50;
    color: white;
}

tr:nth-child(even) {
    background-color: #131313;
}

.mFieldsetTitle {
    background-color: #1a1a1a;
    width: fit-content;
    transform: translate(5px, 4px);
    margin: 0;
    position: absolute;
    padding: 0 5px;
    display: block;
    font-size: 1.09375rem;
    font-weight: bolder;
}
.mFieldset {
    border-radius: 5px;
    border: 2px groove rgb(192, 192, 192);
    padding: 12px 10px 10px 10px;
    margin-top:  15px;
}
</style>