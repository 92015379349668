<template>
     <div v-if="device !== null" style="width: 100%; z-index: 2147483647" ref="sidebar" :id="'videoAside'+playerId">

         <div style="height: calc(100vh - 56px); overflow-y: scroll" class="d-flex">
             <div style="overflow-x: visible; gap: 5px; padding: 5px" class="d-flex flex-column w-100">

                 <!-- Camera Controls -->
                 <VideoAsideCard title="Camera Controls" v-if="device.supportPtz()" asideKey="controls">
                    <GammaPTZControl :device="device" :sourceToken="sourceToken" :dataChannel="dataChannel"/>
                 </VideoAsideCard>

                 <!-- Playback -->
                 <VideoAsideCard title="Playback" v-if="bool" asideKey="playback">
                    <SmartSuitePlayback :device="device" :sourceToken="sourceToken" :dataChannel="dataChannel"/>
                 </VideoAsideCard>

                 <!-- Exports -->
                 <VideoAsideCard title="Exports" v-if="bool" asideKey="exports">
                    <SmartSuiteExports :device="device" :sourceToken="sourceToken" :dataChannel="dataChannel" :archiveOnly="archiveOnly"/>
                 </VideoAsideCard>

                 <!-- Record Interview -->
                 <VideoAsideCard title="Record Interview" v-if="device.findAVSyncArchive() !== null" asideKey="interview">
                    <SmartSuiteInterview :device="device"/>
                 </VideoAsideCard>

                 <!-- Object Detection -->
                 <VideoAsideCard title="Object Detection" asideKey="objectdetection">
                    <SmartSuiteVideoSearch :devices="[device]" :sourceToken="sourceToken" :dataChannel="dataChannel"/>
                 </VideoAsideCard>

                 <!-- Viewers -->
                 <VideoAsideCard :title="viewers + ' Viewer' + (viewers === 1 ? '' : 's')" v-if="bool && !playback" asideKey="viewers">
                    <ViewerList :device="device" :sourceToken="sourceToken" @updateViewers="updateViewers"/>
                 </VideoAsideCard>

                 <!-- Bookmarks -->
                 <VideoAsideCard title="Bookmarks" v-if="bool && !playback" asideKey="bookmarks">
                     <template v-slot:button>
                         <b-button @click="buttonPressed('addBookmark'+ playerId)"
                                   title="Add Bookmark"
                                   size="sm" variant="dark"
                                   style="margin-right: 5px;"><fa-icon :icon="['fas', 'plus']"/>
                         </b-button>
                     </template>
                    <SmartSuiteBookmarks :device="device" :sourceToken="sourceToken" :dataChannel="dataChannel"/>
                 </VideoAsideCard>

                 <!-- Smart Suite Video Share -->
                 <!--<SmartSuiteVideoShare v-if="bool" :device="device"/>-->

                 <!-- Events -->
<!--                 <VideoAsideCard title="Events" v-if="bool && !playback" asideKey="events">
                     <template v-slot:button>
                         <b-button @click="buttonPressed('toggleEventsFilter'+ playerId)"
                                   title="Event Filters"
                                   size="sm" variant="dark"
                                   style="margin-right: 5px;"><fa-icon :icon="['fas', 'filter']"/>
                         </b-button>
                     </template>
                     <SmartSuiteEvents :device="device" :sourceToken="sourceToken" :dataChannel="dataChannel"/>
                 </VideoAsideCard>-->

                 <!-- Edit/View Device Info -->
                 <VideoAsideCard title="View/Edit Device Info" v-if="bool" asideKey="viewEditDeviceInfo">
                     <div class="d-flex justify-content-center">
                         <router-link :to="{
                            name: 'Device Manager',
                            query: {
                                tenantId: device.getTenantId(),
                                hubName: device.getHubName(),
                                id: device.getDeviceId()
                            }
                         }" target="_blank">
                            <b-button size="sm" variant="primary">
                                View/Edit Device Info
                            </b-button>
                         </router-link>
                     </div>
                 </VideoAsideCard>

             </div>
         </div>
     </div>
</template>

<script>
    import { asideMenuCssClasses, validBreakpoints, checkBreakpoint } from '@/views/template_files/navbarJazz/shared';
    import toggleClasses from '../template_files/navbarJazz/shared/toggle-classes';
    import GammaPTZControl from "@/components/GammaPTZControl";
    import SmartSuitePlayback from "@/components/SmartSuitePlayback";
    import SmartSuiteExports from "@/components/SmartSuiteExports";
    import SmartSuiteInterview from "@/components/SmartSuiteInterview";
    import SmartSuiteEvents from "@/components/SmartSuiteEvents";
    import SmartSuiteVideoShare from "@/components/SmartSuiteVideoShare.vue";
    import SmartSuiteVideoSearch from "@/components/SmartSuiteVideoSearch.vue";
    import SmartSuiteBookmarks from "@/components/SmartSuiteBookmarks.vue";
    import ViewerList from "@/views/video_wall/ViewerList";
    import VideoAsideCard from "@/components/VideoAsideCard.vue";
    import PubSub from "pubsub-js";

    export default {
        name: "videoAside",

        props: {
            device: {
                type: Object,
                default: null
            },
            name: {
                type: String,
                default: ""
            },
            mobile: {
                type: Boolean,
                default: false,
            },
            bool: {
                type: Boolean,
                default: false,
            },
            display: {
                type: String,
                default: 'lg'
            },
            playback: {
                type: Boolean,
                default: false
            },
            sourceToken: {
                type: String,
                default: '0'
            },
            dataChannel: {
                type: String,
                default: undefined
            },
            archiveOnly: {
                type: Boolean,
                default: false
            }
        },
        components: {
            SmartSuiteExports,
            SmartSuitePlayback,
            SmartSuiteInterview,
            SmartSuiteEvents,
            SmartSuiteVideoShare,
            SmartSuiteVideoSearch,
            SmartSuiteBookmarks,
            GammaPTZControl,
            ViewerList,
            VideoAsideCard
        },
        data: () => {
            return {
                oldBool: false,
                viewers: 0,
                reboot: {
                    bool: false,
                    rebooting: false
                }
            }
        },
        beforeDestroy() {
            if (this.bool) {
                this.toggle();
            }
        },
        methods: {
            buttonPressed(request, payload) {
                PubSub.publish(request, payload);
            },
            updateViewers(viewers) {
                this.viewers = viewers
            },
            toggle (force) {
                const [display, mobile] = [this.display, this.mobile];
                let cssClass = asideMenuCssClasses[0];
                if (!mobile && display && checkBreakpoint(display, validBreakpoints)) {
                    cssClass = `aside-menu-${display}-show`;
                    toggleClasses(cssClass, asideMenuCssClasses, force);
                }
            },
        },
        computed: {
            classList () {
                return [
                    'navbar-toggler'
                ];
            },
            playerId() {
                return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
            }
        },
        watch: {
            bool() {
                if (this.oldBool !== this.bool) {
                    this.toggle();
                    this.oldBool = this.bool;
                }
            }
        }
    }
</script>

<style scoped>
</style>