export default {
    '1x1': [
        {top: 0, left: 0, height: 12, width: 12, _id: 0}
    ],
    '2x2': [
        {top: 0, left: 0, height: 6, width: 6, _id: 0},
        {top: 0, left: 6, height: 6, width: 6, _id: 1},
        {top: 6, left: 0, height: 6, width: 6, _id: 2},
        {top: 6, left: 6, height: 6, width: 6, _id: 3},
    ],
    '3x3': [
        {top: 0, left: 0, height: 4, width: 4, _id: 0},
        {top: 0, left: 4, height: 4, width: 4, _id: 1},
        {top: 0, left: 8, height: 4, width: 4, _id: 2},
        {top: 4, left: 0, height: 4, width: 4, _id: 3},
        {top: 4, left: 4, height: 4, width: 4, _id: 4},
        {top: 4, left: 8, height: 4, width: 4, _id: 5},
        {top: 8, left: 0, height: 4, width: 4, _id: 6},
        {top: 8, left: 4, height: 4, width: 4, _id: 7},
        {top: 8, left: 8, height: 4, width: 4, _id: 8},
    ],
    '4x4': [
        {top: 0, left: 0, height: 3, width: 3, _id: 0},
        {top: 0, left: 3, height: 3, width: 3, _id: 1},
        {top: 0, left: 6, height: 3, width: 3, _id: 2},
        {top: 0, left: 9, height: 3, width: 3, _id: 3},
        {top: 3, left: 0, height: 3, width: 3, _id: 4},
        {top: 3, left: 3, height: 3, width: 3, _id: 5},
        {top: 3, left: 6, height: 3, width: 3, _id: 6},
        {top: 3, left: 9, height: 3, width: 3, _id: 7},
        {top: 6, left: 0, height: 3, width: 3, _id: 8},
        {top: 6, left: 3, height: 3, width: 3, _id: 9},
        {top: 6, left: 6, height: 3, width: 3, _id: 10},
        {top: 6, left: 9, height: 3, width: 3, _id: 11},
        {top: 9, left: 0, height: 3, width: 3, _id: 12},
        {top: 9, left: 3, height: 3, width: 3, _id: 13},
        {top: 9, left: 6, height: 3, width: 3, _id: 14},
        {top: 9, left: 9, height: 3, width: 3, _id: 15},
    ],
    'Priority Grid (6)': [
        {top: 0, left: 0, height: 8, width: 8, _id: 0},
        {top: 0, left: 8, height: 4, width: 4, _id: 1},
        {top: 4, left: 8, height: 4, width: 4, _id: 2},
        {top: 8, left: 0, height: 4, width: 4, _id: 3},
        {top: 8, left: 4, height: 4, width: 4, _id: 4},
        {top: 8, left: 8, height: 4, width: 4, _id: 5},
    ],
    'Priority Grid (8)': [
        {top: 0, left: 0, height: 9, width: 9, _id: 0},
        {top: 0, left: 9, height: 3, width: 3, _id: 1},
        {top: 3, left: 9, height: 3, width: 3, _id: 2},
        {top: 6, left: 9, height: 3, width: 3, _id: 3},
        {top: 9, left: 0, height: 3, width: 3, _id: 4},
        {top: 9, left: 3, height: 3, width: 3, _id: 5},
        {top: 9, left: 6, height: 3, width: 3, _id: 6},
        {top: 9, left: 9, height: 3, width: 3, _id: 7},
    ],
    'Focus Grid (13)': [
        {top: 0, left: 0, height: 3, width: 3, _id: 0},
        {top: 0, left: 3, height: 3, width: 3, _id: 1},
        {top: 0, left: 6, height: 3, width: 3, _id: 2},
        {top: 0, left: 9, height: 3, width: 3, _id: 3},
        {top: 3, left: 0, height: 3, width: 3, _id: 4},
        {top: 3, left: 3, height: 6, width: 6, _id: 5},
        {top: 3, left: 9, height: 3, width: 3, _id: 6},
        {top: 6, left: 0, height: 3, width: 3, _id: 7},
        {top: 6, left: 9, height: 3, width: 3, _id: 8},
        {top: 9, left: 0, height: 3, width: 3, _id: 9},
        {top: 9, left: 3, height: 3, width: 3, _id: 10},
        {top: 9, left: 6, height: 3, width: 3, _id: 11},
        {top: 9, left: 9, height: 3, width: 3, _id: 12},
    ],
}