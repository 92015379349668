<template>
    <div>
        <!-- Add Bookmark Modal -->
        <b-modal v-model="addBookmarkModal" title="Add Bookmark" class="modal-dark" @close="bookmarkNoteText = ''">
            <TextValueBox class="flexElementFull" text="Camera" :value="device.getDeviceName() || '-'"/>
            <TextValueBox class="flexElementFull" text="Time" :value="createBookmarkTimeHuman || '-'"/>
            <TextValueBox class="flexElementFull" text="Note">
                <b-form-textarea v-model="bookmarkNoteText" id="bookmarkTextModal" autofocus></b-form-textarea>
            </TextValueBox>

            <div slot="modal-footer">
                <b-button variant="danger" size="sm" @click="addBookmarkModal = false; bookmarkNoteText = ''">Close</b-button>
                <b-button variant="primary" size="sm" style="margin-left: 10px;" @click="addBookmark">Add Bookmark</b-button>
            </div>
        </b-modal>

        <div style="gap: 10px">
            <div v-if="bookmarks !== null && archiveDataChannel !== null && bookmarks[archiveDataChannel].length > 0" style="max-height: 500px; overflow-y: auto">
                <div v-for="(bookmark, index) in bookmarks[archiveDataChannel]">
                    <div v-if="index === 0 && bookmark.pinned !== true">
                        <div class="d-flex justify-content-center"><b>{{new Date(bookmark.time).toLocaleDateString()}}</b></div>
                    </div>
                    <div v-else-if="index !== 0 && bookmark.pinned !== true">
                        <div v-if="bookmarks[archiveDataChannel][index-1].pinned === true ||
                                   (newestFirst === false && Math.floor(accountForTimezone(bookmarks[archiveDataChannel][index-1].time)/(86400*1000)) < Math.floor(accountForTimezone(bookmark.time)/(86400*1000))) ||
                                   (newestFirst === true && Math.floor(accountForTimezone(bookmarks[archiveDataChannel][index-1].time)/(86400*1000)) > Math.floor(accountForTimezone(bookmark.time)/(86400*1000)))">
                            <div class="d-flex justify-content-center"><b>{{new Date(bookmark.time).toLocaleDateString()}}</b></div>
                        </div>
                    </div>
                    <div style="padding: 4px 5px; cursor: pointer;" @click="goToBookmark(bookmark)" :title="'Go To '+ new Date(bookmark.time).toLocaleString()">
                        <div class="rounded w-100" style="padding: 5px 0; background-color: #2e2e2e">
                            <div class="d-flex justify-content-between">
                                <div style="padding: 0 5px; font-size: 12px;">Time: {{new Date(bookmark.time).toLocaleString()}}</div>
                                <div v-if="selectedDeleteBookmark === bookmark.id" style="margin-top: -3px;">
                                    <fa-icon :icon="['fas', 'remove']" fixed-width
                                             @click.stop="selectedDeleteBookmark = null"
                                             style="font-size: 12px;"
                                             title="Cancel"/>
                                    <fa-icon :icon="['fas', 'check']" fixed-width
                                       @click.stop="deleteBookmark(bookmark)"
                                       style="font-size: 12px;"
                                       title="Confirm"/>
                                </div>
                                <div v-else style="margin-top: -3px;">
                                    <fa-icon :icon="['fas', 'trash']" fixed-width
                                       @click.stop="selectedDeleteBookmark = bookmark.id"
                                       style="font-size: 12px;"
                                       title="Delete Bookmark"/>
                                    <fa-icon :icon="['fas', 'thumb-tack']" fixed-width
                                       @click.stop="pinBookmark(bookmark)"
                                       style="font-size: 12px;"
                                       title="Pin Bookmark"
                                       :style="bookmark.pinned === true ? 'color: white': 'color: #555555'"/>
                                </div>
                            </div>
                            <div style="padding: 0 5px; font-size: 12px; word-break: break-word;">Note: {{bookmark.note}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else style="text-align: center;">
                No Bookmarks
            </div>
        </div>
    </div>
</template>

<script>

import PubSub from "pubsub-js";
import TextValueBox from "@/components/TextValueBox";
import {v4 as uuidv4} from 'uuid';
import authStore from "@/store/authStore";
export default {
    name: "SmartSuiteBookmarks",
    props: {
        device: {
            type: Object
        },
        sourceToken: {
            type: String,
            default: '0'
        },
        dataChannel: {
            type: String,
            default: undefined
        }
    },
    components: {
      TextValueBox
    },
    data() {
        return {
            bookmarks: null,
            archiveDataChannel: null,
            addBookmarkModal: false,
            createBookmarkTimeUnix: null,
            pubsubs: [],
            bookmarkNoteText: '',
            newestFirst: true,
            selectedDeleteBookmark: null,
            name: ''
        }
    },
    async  created() {
        //this method is run to ensure that the user's local storage has a location to store bookmarks in
        await this.getName();
        await this.getLocalStorage();
        await this.getArchiveDataChannel();
        this.pubsubs.push(PubSub.subscribe('sendCurrentTime'+this.playerId, (msg, data) => {
            //sends a unix timestamp of where the red bar is
            this.createBookmarkTimeUnix = data;
        }));
        this.pubsubs.push(PubSub.subscribe('getBookmarks'+this.playerId, (msg, data) => {
            if (this.bookmarks[this.archiveDataChannel] !== undefined) {
                PubSub.publish('sendBookmarks' + this.playerId, this.bookmarks[this.archiveDataChannel]);
            } else {
                PubSub.publish('sendBookmarks' + this.playerId, []);
            }
        }));
        this.pubsubs.push(PubSub.subscribe('addBookmark'+ this.playerId, (msg, data) => {
            this.openAddBookmarkModal();
        }));
    },
    beforeDestroy() {
        //close all pubsubs
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub)
        })
    },
    methods: {
        async test() {
            this.newestFirst = !this.newestFirst;
        },
        async getName() {
            let user = await authStore.dispatch("getUser");
            if (user.firstname || user.lastname) {
                this.name =  user.firstname + " " + user.lastname;
            } else {
                this.name = user.name;
            }
        },
        setLocalStorage() {
            window.localStorage.setItem('bookmarks', JSON.stringify(this.bookmarks));
        },
        goToBookmark(bookmark) {
            PubSub.publish('startPlayback'+this.playerId, bookmark.time)
        },
        sendBookmarks() {
            PubSub.publish('sendBookmarks'+this.playerId, this.bookmarks[this.archiveDataChannel]);
        },
        accountForTimezone(time) {
            return time-(new Date().getTimezoneOffset()*60000)
        },
        async pinBookmark(bookmark) {
            bookmark.pinned = !bookmark.pinned;
            await this.timeSort(this.bookmarks[this.archiveDataChannel]);
            this.setLocalStorage();
        },
        timeSort(bookmarks) {
            if (this.newestFirst === false) {
                return bookmarks.sort((a, b) => {
                    if ((a.pinned === true && b.pinned === true) || (a.pinned === false & b.pinned === false)) {
                        if (a.time > b.time)
                            return 1;
                        if (a.time < b.time)
                            return -1;
                        return 0;
                    } else if (a.pinned === true) {
                        return -1;
                    } else if (b.pinned === true) {
                        return 1;
                    }
                })
            } else {
                return bookmarks.sort((a, b) => {
                    if ((a.pinned === true && b.pinned === true) || (a.pinned === false & b.pinned === false)) {
                        if (a.time < b.time)
                            return 1;
                        if (a.time > b.time)
                            return -1;
                        return 0;
                    } else if (a.pinned === true) {
                        return -1;
                    } else if (b.pinned === true) {
                        return 1;
                    }
                })
            }
        },
        async addBookmark() {
            let newBookmark = {
                    time: this.createBookmarkTimeUnix,
                    note: this.bookmarkNoteText,
                    pinned: false,
                    id: uuidv4(),
                    user: this.name
            }
            this.bookmarks[this.archiveDataChannel].push(newBookmark);
            this.addBookmarkModal = false;
            this.createBookmarkTimeUnix = null;
            this.bookmarkNoteText = '';
            await this.timeSort(this.bookmarks[this.archiveDataChannel]);
            this.sendBookmarks();
            this.setLocalStorage();
        },
        async deleteBookmark(bookmark) {
            let index = this.bookmarks[this.archiveDataChannel].findIndex(bookmark2 => {
                return bookmark2.id === bookmark.id;
            });
            if (index !== -1) {
                this.bookmarks[this.archiveDataChannel].splice(index, 1);
                await this.sendBookmarks();
                this.setLocalStorage();
            }
        },
        openAddBookmarkModal() {
            PubSub.publish('getCurrentTime'+this.playerId);
            this.addBookmarkModal = true;
        },
        async getArchiveDataChannel() {
            let archiveStream = this.device.getArchiveStream(this.sourceToken);
            if (archiveStream !== undefined) {
                this.archiveDataChannel = archiveStream.dataChannel;
                if (this.bookmarks[this.archiveDataChannel] === undefined) {
                    this.bookmarks[this.archiveDataChannel] = [];
                    await this.setLocalStorage();
                }
            }
        },
        getLocalStorage() {
            let bookmarkObject = window.localStorage.getItem('bookmarks');
            if (bookmarkObject === null) {
                window.localStorage.setItem('bookmarks', JSON.stringify({}));
                this.bookmarks = {};
            } else {
                this.bookmarks = JSON.parse(bookmarkObject);
            }
        }
    },
    computed: {
        playerId() {
            return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
        },
        createBookmarkTimeHuman() {
            return new Date(this.createBookmarkTimeUnix).toLocaleString();
        },
    },
    watch: {
        newestFirst() {
            if (this.bookmarks[this.archiveDataChannel] !== undefined) {
                this.timeSort(this.bookmarks[this.archiveDataChannel]);
            }
        }
    }
}
</script>

<style scoped>
    .flexElementFull {
        flex: 100%;
        padding: 5px
    }
</style>