<template>
    <div>
        <div style="margin-bottom: 5px;" class="d-flex w-100 justify-content-center">
            <b>Status:</b>&nbsp;{{avRecording === true ? 'Recording' : 'Not Recording'}}
        </div>
        <div class="d-flex justify-content-center" style="gap: 10px">
            <b-button :variant="avRecording === true || checkingRecordingUpdate === true ? 'secondary' : 'success'"
                      :disabled="avRecording === true || checkingRecordingUpdate === true" size="sm"
                      @click="startStopArchive()"
                      title="Start Recording">
                <fa-icon v-if="checkingRecordingUpdate === true && avRecording === false"
                         :icon="['fas', 'spinner']" spin style="padding: 0 7px;"/>
                <span v-else>
                   Start
                </span>
            </b-button>
            <b-button :variant="avRecording !== true || checkingRecordingUpdate === true ? 'secondary' : 'danger'"
                      :disabled="avRecording === false || checkingRecordingUpdate === true" size="sm"
                      @click="startStopArchive()"
                      title="Stop Recording">
                <fa-icon v-if="checkingRecordingUpdate === true && avRecording === true"
                         :icon="['fas', 'spinner']" spin style="padding: 0 7px;"/>
                <span v-else>
                   Stop
                </span>
            </b-button>
        </div>
    </div>
</template>

<script>

export default {
    name: "SmartSuiteInterview",
    props: {
        device: {
            type: Object
        }
    },
    data() {
        return {
            avArchive: null,
            avRecording: false,
            deviceInterval: null,
            checkingRecordingUpdate: false,
        }
    },
    async created() {
        this.avArchive = await this.device.findAVSyncArchive()
        if (this.avArchive !== null) {
            this.avRecording = this.avArchive.isRunning;
        }
        this.deviceInterval = setInterval(async () => {
            this.avArchive = await this.device.findAVSyncArchive()
            if (this.avArchive !== null) {
                if (this.avRecording !== this.avArchive.isRunning) {
                    this.avRecording = this.avArchive.isRunning;
                    this.checkingRecordingUpdate = false;
                }
            }
        }, 1500);
    },
    beforeDestroy() {
        clearInterval(this.deviceInterval);
    },
    methods: {
        startStopArchive() {
            if (this.avArchive !== null) {
                this.checkingRecordingUpdate = true;
                if(this.avArchive.isRunning === true){
                    this.device.stopArchive(this.avArchive.sourceToken, this.avArchive.dataChannel)
                } else {
                    this.device.startArchive(this.avArchive.sourceToken, this.avArchive.dataChannel)
                }
            }
        },
    }
}
</script>

<style scoped>

</style>