<template>
    <nav class="sidebar-nav">
        <VuePerfectScrollbar :class="{'scroll-area': mobile}" :settings="psSettings" @ps-scroll-y="scrollHandle">
            <ul class="nav">
                <template v-for="(item, index) in navItems">
                    <template v-if="item.title">
                        <SidebarNavTitle :key="index" :name="item.name" :classes="item.class" :wrapper="item.wrapper"/>
                    </template>
                    <template v-else-if="item.divider">
                        <SidebarNavDivider :key="index" :classes="item.class"/>
                    </template>
                    <template v-else-if="item.label">
                        <SidebarNavLabel :key="index" :name="item.name" :url="item.url" :icon="item.icon"
                                         :label="item.label" :classes="item.class"/>
                    </template>

                    <template v-else>
                        <!-- Video Wall Device List -->
                        <template v-if="item.children && item.children.length > 0 && isVideoWall">
                            <!-- First level dropdown -->
                            <SidebarNavDropdown :name="item.name" :key="tenantIter" :url="item.url" :icon="item.icon" style="color:white">
                                <!--   -->
                                <template v-for="tenantId in tenantsSorted">
                                    <template v-if=" Object.keys(videoWallTenants).length !== 0 && tenantId !== true">

                                        <!-- this displays the tenant title -->
                                        <div class="w-100 d-flex align-items-center justify-content-between" style="padding: 0 5px"
                                             @click="toggleTenant(!expanded[tenantId], tenantId, 'name')">
                                            <div style="font-weight: 1000; font-size: .95rem">
                                                {{getTenantName(tenantId)}}
                                            </div>
                                            <CarrotToggler @input="(e) => toggleTenant(e, tenantId, 'carrotToggler')" v-model="expanded[tenantId]"/>
                                        </div>
                                        <b-collapse v-model="expanded[tenantId]">
                                            <div style="background-color: #090909; padding-bottom: 5px">
                                                <div>
                                                    <div v-for="edgeService in edgeServicesSorted[tenantId]">
                                                        <!-- this displays the name of the edge service -->
                                                        <SidebarNavDropdown :name="edgeService.name" :icon="''" style="font-weight: bold"
                                                                            :id="edgeService.name" @dropDownClicked="getEdgeServiceDevices(edgeService.name, tenantId)">
                                                            <div :key="tenantId + edgeIter">
                                                                <div v-if="devicesSorted[tenantId][edgeService.name].length > 0">
                                                                    <li v-if="deviceIsShown(tenantId, device) && edgeDevices[tenantId][edgeService.name][device.id].getStreams().length > 0" style="cursor:pointer; margin-left: 5px"
                                                                        class="nav-item d-flex" v-for="(device, index) in devicesSorted[tenantId][edgeService.name]">
                                                                        <div style="position: relative">
                                                                            <div v-if="index + 1 === devicesSorted[tenantId][edgeService.name].length || edgeDevices[tenantId][edgeService.name][device.id].lastInFilter === true"
                                                                                 style="border-left: 1px solid white; height: 10px;"/>
                                                                            <div v-else style="border-left: 1px solid white; height: 100%;"/>
                                                                        </div>
                                                                        <div style="border-top: 1px solid white; width: 10px; transform: translate(0, 9px)"/>&nbsp;
                                                                        <!-- this displays the name of the device -->
                                                                        <SidebarNavLink style="padding: 0;" :purpose="'videoWall'" :name="device.name"
                                                                                        :url="edgeDevices[tenantId][edgeService.name][device.id].getUrl()"
                                                                                        :updateKey="getIndexKey(tenantId)"
                                                                                        :icon="edgeDevices[tenantId][edgeService.name][device.id].icon" :curObject="edgeDevices[tenantId][edgeService.name][device.id]"/>
                                                                    </li>
                                                                </div>
                                                                <div v-else style="text-align: center;">
                                                                    <fa-icon :icon="['fas', 'spinner']" spin/>
                                                                </div>
                                                            </div>
                                                        </SidebarNavDropdown>
                                                    </div>
                                                </div>
<!--                                                <div v-else style="text-align: center;">
                                                    <fa-icon :icon="['fas', 'spinner']" spin/>
                                                </div>-->
                                            </div>
                                        </b-collapse>
                                    </template>
                                    <template v-else-if="tenantId === true">
                                        <SidebarForm class="p-2">
                                            <b-form-input v-model="cameraInput" placeholder="Filter Cameras"/>
                                        </SidebarForm>
                                    </template>
                                </template>
                            </SidebarNavDropdown>
                        </template>
                        <!-- This is the icon & name for all items on the left side nav bar -->
                        <template v-else>
                            <SidebarNavItem :key="index" :classes="item.class">
                                <SidebarNavLink :name="item.name" :url="item.url" :icon="item.icon"/>
                            </SidebarNavItem>
                        </template>
                    </template>
                </template>
            </ul>
            <slot></slot>
        </VuePerfectScrollbar>
    </nav>
</template>

<script>
import SidebarNavDivider from './SidebarNavDivider'
import SidebarNavDropdown from './SidebarNavDropdown'
import SidebarNavLink from './SidebarNavLink'
import SidebarNavTitle from './SidebarNavTitle'
import SidebarNavItem from './SidebarNavItem'
import SidebarNavLabel from './SidebarNavLabel'
import SidebarForm from './SidebarForm'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import '@/shared/jmuxer/jmuxplayer.js'
import tenantStore from "@/store/tenantStore";
import PubSub from 'pubsub-js';
import Vue from 'vue';
import deviceStore from "@/store/deviceStore";
import CarrotToggler from "@/components/CarrotToggler.vue";

export default {
    name: 'SidebarNav',
    components: {
        CarrotToggler,
        SidebarNavDivider,
        SidebarNavDropdown,
        SidebarNavLink,
        SidebarNavTitle,
        SidebarNavItem,
        SidebarNavLabel,
        SidebarForm,
        VuePerfectScrollbar
    },
    props: {
        navItems: {
            type: Array,
            required: true,
            default: () => []
        },
        isVideoWall: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cameraInput: "",
            tenants: null,
            videoWallTenants: {},
            tenantDeviceIter: [],
            deviceIter: 111,
            edgeIter: 333,
            tenantIter: 555,
            edgeDevices: {},
            devicesSorted: {},
            edgeServicesSorted: {},
            tenantsSorted: [],
            expanded: {},
            connectedEdgeServices: {},
            deviceUpdatePubsubs: []
        }
    },
    async created() {
        this.tenants = await tenantStore.dispatch('getTenants').catch(error => {
            console.error(error);
            this.$mToast({
                title: error.response.status + ' Error',
                text: "Tenant couldn't be retrieved: " + error.response.statusText,
                style: 'error'
            });
        })
        for (const [index, tenant] of this.tenants.entries() ) {
            this.tenantDeviceIter.push({tenant: tenant._id, iter: 0});
            Vue.set(this.expanded, tenant._id, false);
            Vue.set(this.connectedEdgeServices, tenant._id, {});
            if (this.edgeDevices[tenant._id] === undefined) {
                this.edgeDevices[tenant._id] = {};
                this.devicesSorted[tenant._id] = {};
                this.edgeServicesSorted[tenant._id] = [];
            }
            this.sortTenants(tenant._id);
            this.addSearchBar();
        }
    },
    updated() {
        if (this.deviceUpdatePubsubs.length === 0 && this.$router.currentRoute.path === '/video-wall' && this.tenants !== null) {
            for (const [index, tenant] of this.tenants.entries()) {
                this.deviceUpdatePubsubs.push(PubSub.subscribe('ssdevicesupdate-' + tenant._id, (msg, devices) => {
                    this.updateDevices(tenant._id, devices);
                }));
            }
        }
    },
    async beforeDestroy() {
        await deviceStore.dispatch('clearSourceListeners');
        this.deviceUpdatePubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        });
    },
    methods: {
        async getEdgeServiceDevices(edgeService, tenantId) {
            //toggle connected value and then send to store to open/close listener
            this.connectedEdgeServices[tenantId][edgeService] = !this.connectedEdgeServices[tenantId][edgeService];
            let devices = await deviceStore.dispatch('getSmartSuiteDevicesByEdgeService', {
                tenantId: tenantId,
                edgeService: edgeService,
                isConnected: this.connectedEdgeServices[tenantId][edgeService]
            });
            //if you are opening an edge service tab
            if (this.connectedEdgeServices[tenantId][edgeService] === true) {
                PubSub.publish('closeLivestreamSupport', {
                   tenantId: tenantId,
                   edgeService: edgeService
                });
                this.updateDevices(tenantId, devices);
            } else if (this.connectedEdgeServices[tenantId][edgeService] === false) {
                //if you are closing an edge service tab
                PubSub.publish('openLivestreamSupport', {
                    tenantId: tenantId,
                    edgeService: edgeService
                });
            }
        },
        getTenantName(tenantId) {
            return this.tenants.find(tenant => tenant._id === tenantId).tenantName;
        },
        getIndexKey(tenant) {
            let index = this.tenantDeviceIter.findIndex(obj => {
                return obj.tenant === tenant;
            });
            return this.tenantDeviceIter[index].tenant + this.tenantDeviceIter[index].iter;
        },
        updateDevices(tenantId, devices) {
            if (devices && devices.length !== 0) {
                let count = 0;
                devices.forEach(ssDevice => {
                    //if (ssDevice.getStreams().length > 0) {
                    let hubName = ssDevice.getHubName();
                    Vue.set(this.edgeDevices[tenantId][hubName], ssDevice.getDeviceId(), ssDevice);

                    let index = this.devicesSorted[tenantId][hubName].findIndex(device => {
                        return device.id === ssDevice.getDeviceId();
                    })
                    //creates a location with sorted devices by edge service
                    if (index !== -1) {
                        Vue.set(this.devicesSorted[tenantId][hubName], index, {id: ssDevice.getDeviceId(), name: ssDevice.getDeviceName()});
                    } else {
                        this.devicesSorted[tenantId][hubName].push({id: ssDevice.getDeviceId(), name: ssDevice.getDeviceName()})
                        this.edgeIter++;
                    }
                    //creates a location with sorted edge services
                    let index2 = this.edgeServicesSorted[tenantId].findIndex(edgeService => {
                        return edgeService.name === hubName;
                    })
                    if (index2 !== -1) {
                        Vue.set(this.edgeServicesSorted[tenantId], index2, {tenant: tenantId, name: hubName});
                    } else {
                        this.edgeServicesSorted[tenantId].push({tenant: tenantId, name: hubName});
                        this.tenantIter++;
                    }
                    count++;
                    if (count === devices.length) {
                        for (const tenant in this.devicesSorted) {
                            //sorts the edge services by tenant
                            this.edgeServicesSorted[tenant] = this.edgeServicesSorted[tenant].sort((a,b) => {
                                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                                    return 1;
                                }
                                if  (a.name.toUpperCase() < b.name.toUpperCase()) {
                                    return -1;
                                }
                                return 0;
                            });
                            for (const edgeService in this.devicesSorted[tenant]) {
                                //sorts the devices by edge service
                                this.devicesSorted[tenant][edgeService] = this.devicesSorted[tenant][edgeService].sort((a,b) => {
                                    if (a.name.toUpperCase() > b.name.toUpperCase()) {
                                        return 1;
                                    }
                                    if  (a.name.toUpperCase() < b.name.toUpperCase()) {
                                        return -1;
                                    }
                                    return 0;
                                });
                            }
                        }
                    }
                    //}
                })
                this.sortTenants(tenantId, devices);
                //sorts devices alphabetically
                for (const key in this.videoWallTenants) {
                    if (key !== 'form') {
                        this.videoWallTenants[key].sort((a, b) => {
                            if (a.getDeviceName() < b.getDeviceName()) {
                                return -1;
                            }
                            if (a.getDeviceName() > b.getDeviceName()) {
                                return 1;
                            }
                            return 0;
                        })
                    }
                }
                this.addSearchBar();
            }
        },
        async toggleTenant(boolean, tenantId, locationOfOrigin) {
            //set up a listener to format devices returned for the specified tenant
            await deviceStore.dispatch('setMediahubListener', tenantId);
            //increment iter to force new key for reactivity
            let index = this.tenantDeviceIter.findIndex(obj => {
                return obj.tenant === tenantId;
            });
            Vue.set(this.tenantDeviceIter, index, {tenant: tenantId, iter:this.tenantDeviceIter[index].iter + 1});
            //get edge services for the tenant we clicked on to display
            let edgeServices = await deviceStore.dispatch('getTenantEdgeServices', tenantId);
            if (edgeServices && edgeServices.length > 0) {
                //sort array of edgeService
                this.edgeServicesSorted[tenantId] = this.sortArray(edgeServices).map(edgeService => {
                    return {name: edgeService};
                });

                //create locations to house devices
                edgeServices.forEach(hubName => {
                    if (this.connectedEdgeServices[tenantId] === undefined) {
                        Vue.set(this.connectedEdgeServices[tenantId], hubName, false);
                    }
                    if (this.edgeDevices[tenantId][hubName] === undefined) {
                        this.edgeDevices[tenantId][hubName] = {};
                        this.devicesSorted[tenantId][hubName] = [];
                        this.edgeIter++;
                    }
                });
            }
            if (locationOfOrigin !== 'carrotToggler') {
                Vue.set(this.expanded, tenantId, boolean);
            } else {
                //for the carrot toggler the v-model is for whatever reason nonreactive, and setting the value to the same
                //value is also nonreactive, so we need to toggle the value for reactiveness to display edge services
                Vue.set(this.expanded, tenantId, !boolean);
                Vue.set(this.expanded, tenantId, boolean);
            }
        },
        addSearchBar() {
            this.videoWallTenants = Object.assign(this.videoWallTenants, {form: true});
            let index3 = this.tenantsSorted.findIndex(search => {
                return search === true;
            });
            if (index3 === -1) {
                this.tenantsSorted.splice(0,0, true);
            }
        },
        sortTenants(tenantId, devices = []) {
            //add tenant and then sorts them
            if (this.videoWallTenants[tenantId] === undefined) {
                //refreshes the video wall if done too often the tabs will keep closing
                this.tenantsSorted.push();
                this.tenantIter++;
            }
            if (this.videoWallTenants[tenantId] !== undefined) {
                this.videoWallTenants[tenantId].splice(0, this.videoWallTenants[tenantId].length, ...devices);
            } else {
                this.videoWallTenants[tenantId] = [];
                this.videoWallTenants[tenantId].push(...devices);
                let index = this.tenantsSorted.findIndex(_ => {
                    if (_ !== true) {
                        return _.toUpperCase() > tenantId.toUpperCase();
                    }
                });
                if (index !== -1) {
                    this.tenantsSorted.splice(index, 0, tenantId);
                } else {
                    this.tenantsSorted.push(tenantId);
                }
            }
        },
        sortArray(array) {
            return array.sort((a, b) => {
                if (a < b)
                    return -1;
                if (a > b)
                    return 1;
                return 0;
            });
        },
        deviceIsShown(tenant, selectedDevice) {
            let index = this.videoWallTenants[tenant].findIndex(device => {
                return device.getDeviceId() === selectedDevice.id;
            })
            if (index !== -1) {
                return this.videoWallTenants[tenant][index].isNavBarShown() === true;
            }
            return false;
        },
        getDevice(tenant, deviceId) {
            let index = this.videoWallTenants[tenant].findIndex(device => {
                return device.getDeviceId() === deviceId;
            })
            if (index !== -1) {
                return this.videoWallTenants[tenant][index];
            }
            return null;
        },
        scrollHandle(evt) {},
    },
    watch: {
        cameraInput: function () {
            //if there are tenants
            if (Object.keys(this.edgeDevices).length > 0) {
                //for each tenant
                for (const tenant in this.edgeDevices) {
                    let showTenant = false;
                    //if the tenant has edge devices
                    if (Object.keys(this.edgeDevices[tenant]).length > 0) {
                        //for each edge service on the specific tenant
                        for (const [index, edgeService] of Object.keys(this.edgeDevices[tenant]).entries()) {
                            let showEdgeService = false;
                            let count = 0;
                            this.devicesSorted[tenant][edgeService].forEach(device => {
                                if (this.cameraInput !== '') {  //set navbarshown to true if the camera name contains the filter string
                                    this.edgeDevices[tenant][edgeService][device.id].setNavBarShown(this.edgeDevices[tenant][edgeService][device.id].getDeviceName().toUpperCase().includes(this.cameraInput.toUpperCase()));
                                } else {    //set to true if the filter string in empty
                                    this.edgeDevices[tenant][edgeService][device.id].setNavBarShown(true)
                                }
                                if (this.edgeDevices[tenant][edgeService][device.id].isNavBarShown() === true) {    //if one device is true show everything above it
                                    showEdgeService = true;
                                    showTenant = true;
                                }
                                count++;
                                if (count === Object.keys(this.edgeDevices[tenant][edgeService]).length) {
                                    //find the index of the last device in an edge service that should be shown in the filter
                                    let temp = this.devicesSorted[tenant][edgeService].map(device2 => this.edgeDevices[tenant][edgeService][device2.id].isNavBarShown());
                                    let index = temp.lastIndexOf(true);
                                    this.devicesSorted[tenant][edgeService].forEach((device3, index2) => {
                                        this.edgeDevices[tenant][edgeService][device3.id].setLastInFilter(index === index2)
                                    })
                                    if (this.cameraInput === '') {
                                        document.getElementById(edgeService).classList.remove('open');
                                        if (document.getElementById(edgeService+'icon').classList.contains("fa-folder-open")) {
                                            document.getElementById(edgeService+'icon').classList.remove("fa-folder-open");
                                        }
                                        if (!document.getElementById(edgeService+'icon').classList.contains("fa-folder-open")) {
                                            document.getElementById(edgeService+'icon').classList.add("fa-folder");
                                        }
                                    } else {
                                        let element = document.getElementById(edgeService+'icon').classList
                                        if (showTenant === true) {
                                            this.expanded[tenant] = true;
                                            document.getElementById(edgeService).classList.add("open");
                                            element.add("fa-folder-open");
                                            element.remove("fa-folder");
                                        } else {
                                            this.expanded[tenant] = false;
                                            document.getElementById(edgeService).classList.remove("open");
                                            element.remove("fa-folder-open");
                                            element.add("fa-folder");
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            }
        },
    },
    computed: {
        psSettings: () => {
            // ToDo: find better rtl fix
            return {
                maxScrollbarLength: 200,
                minScrollbarLength: 40,
                suppressScrollX: getComputedStyle(document.querySelector('html')).direction !== 'rtl',
                wheelPropagation: true,
                interceptRailY: styles => ({...styles, height: 0})
            }
        },
        mobile() {
            return screen.width < 992
        },
    },
}
</script>

<style scoped lang="css">
.scroll-area {
    padding-bottom: 72px;
}
</style>
