<template>
    <div>
        <div v-for="userId in userIds" :key="'user-' + device.getDeviceId() + '-' + userId"
             class="expandContents">
            <fa-icon :icon="['fas', 'circle']" style="font-size: 7px; margin-right: 5px; transform: translateY(-2px)"/>{{ userId }}
        </div>
    </div>
</template>

<script>
import deviceStore from "@/store/deviceStore";
import PubSub from "pubsub-js";
export default {
    name: "ViewerList",
    props: {
        device: Object,
        sourceToken: String
    },
    data () {
        return {
            userIds: [],
            sub: null,
        }
    },
    created() {
        this.getUsers();
        this.sub = PubSub.subscribe('camerasupdate-' + this.device.getTenantId(), () => {
            this.getUsers();
        });
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.sub);
    },
    methods: {
        async getUsers() {
            this.userIds = await deviceStore.dispatch(
                'getDeviceViewerList',
                {
                    tenantId: this.device.getTenantId(),
                    deviceId: this.device.getDeviceId(),
                    sourceToken: this.sourceToken,
                    isWatching: true
                }
            );
            if (this.userIds && Array.isArray(this.userIds)) {
                this.$emit('updateViewers', this.userIds.length)
            }
        },
    }
}
</script>

<style scoped>

</style>